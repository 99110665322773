export default {
  copy_to_clipboard(copystr) {
    const t = document.createElement('textarea');
    document.body.appendChild(t);
    t.value = copystr;
    t.select();
    document.execCommand('copy');
    document.body.removeChild(t);
  },

};
