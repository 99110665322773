<template>
    <el-card class="main-box mt50">
      <el-row  :gutter="40">
        <el-col :lg="14">
            <highcharts class="chart_area" :options="chartOptions" ref="lineCharts"></highcharts>
        </el-col>
        <el-col :lg="10" style="height:100%">
          <div class="mian-statistics">
            <div class="mian-statistics-btn ico_price pt-xs-20">
              {{ $t('mainBtnPrice') }}
              <p class="fl_right">
                <span>${{ priceData }}</span>
                <span></span>
              </p>
            </div>
            <div class="mian-statistics-btn ico_account">
              {{ $t('mainBtnAccount') }}
              <p class="fl_right">
                <span v-html="$options.filters.currency(statsData.tot_acc)"></span>
                <span></span>
              </p>
            </div>
            <div class="mian-statistics-btn ico_height">
              {{ $t('mainBtnHeight') }}
              <p class="fl_right">
                <span v-html="$options.filters.currency(statsData.height)
                             + ' / ' + parseInt(statsData.sec) + 's'"></span>
                <span></span>
              </p>
            </div>
            <div class="mian-statistics-btn ico_transactions">
              {{ $t('mainBtnTransaction') }}
              <p class="fl_right">
                <span v-html="$options.filters.currency(statsData.total_txs)"></span>
                <span></span>
              </p>
            </div>
            <div class="mian-statistics-btn ico_staked">
              {{ $t('txDelegated') }}
              <p class="fl_right">
                <span v-html="this.$options.filters.currency(statsData.tot_bond)"></span>
                <span></span>
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
</template>

<script>
export default {
  name: 'Mchart',
  props: {
    statsData: Object,
    priceData: String,
  },
  data() {
    return {
      chart: {
        width: 740,
        height: 290,
      },
      chartOptions: {
        title: { text: '' },
        legend: {
          verticalAlign: 'top',
          symbolRadius: 0,
          fontSize: '14px',
        },
        plotOptions: {
          series: {
            label: {
              connectorAllowed: false,
            },
          },
        },
        credits: { enabled: false },
        responsive: {
          rules: [{
            condition: { maxWidth: 500 },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          }],
        },
        yAxis: [{
          title: false,
          labels: { format: '{value}', style: { color: '#fc6060' } },
        }, {
          title: false,
          labels: { format: '{value}', style: { color: '#5568d4' } },
          opposite: true,
        }],
        xAxis: {
          labels: {
            formatter() {
              return this.value.toString().substring(11, 16);
            },
          },
          categories: [],
        },
        colors: ['#5568d4', '#fc6060'],
        series: [],
      },
    };
  },
  created() {
    this.$axios.get('/top/graph')
      .then((res) => {
        if (res.data.ret_code === 200) {
          const category = [];
          const heightData = [];
          const totbonData = [];
          res.data.data.map((item) => {
            // category.push(`${item.reg_dt.substring(11, 16)}`);
            category.push(`${item.reg_dt}`);
            heightData.push(item.total_txs);
            totbonData.push(item.tot_acc);
            return item;
          });
          this.chartOptions.xAxis.categories = category;
          this.chartOptions.series.splice(0, 2);
          this.chartOptions.series.push({ yAxis: 1, name: 'Total Accounts', data: totbonData });
          this.chartOptions.series.push({ yAxis: 0, name: 'Total Transactions', data: heightData });
        }
      })
      .catch((error) => { this.$message.error(error); });
  },
};
</script>
