import Vue from 'vue';
import ElementUI from 'element-ui';
import HighchartsVue from 'highcharts-vue';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ko';
import axios from './lib/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './lang/lang';
import './assets/elementui.css';
import './assets/ui.css';
import filters from './filters';
import func from './func';

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.prototype.$func = func;
Vue.use(ElementUI, { locale });
Vue.use(HighchartsVue);

Vue.mixin({
  filters,
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
