<template>
      <el-row>
        <div class="main_bg"></div>
        <div class="container main_contents_area fl_wrap" style="margin-bottom:180px;">
          <el-col :sm="24">
              <Mbanner :banners="bannerData"></Mbanner>
              <Mchart :statsData="statsData" :priceData="priceData"></Mchart>
          </el-col>
          <el-col :sm="24">
            <div class="display_grid">
              <div class="mainTable1">
                <Mtable :title="$t('mainTbTitle1')"
                      :columnes="columnes1"
                      :tableData="blocksData"
                      routerName="blocks"
                      :buttonName="$t('mainTbBtton1')"></Mtable>
              </div>
              <div class="mainTable2">
                <Mtable :title="$t('mainTbTitle2')"
                        :columnes="columnes2"
                        :tableData="txtData"
                        routerName="transactions"
                        :buttonName="$t('mainTbBtton2')"></Mtable>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
</template>

<script>
import Mbanner from '@/components/Mbanner.vue';
import Mchart from '@/components/Mchart.vue';
import Mtable from '@/components/Mtable.vue';

export default {
  name: 'Home',
  components: {
    Mbanner,
    Mchart,
    Mtable,
  },
  data() {
    return {
      columnes1: [
        {
          label: 'columnHeight', prop: 'block_nm', width: '160', class: 'txt_color_org tdlink', router: 'blocksDetail', key: 'block_nm',
        },
        {
          label: 'columnValidatorId', prop: 'moniker', width: '160', class: 'tdlink', router: 'validatorDetail', key: 'operator_addr',
        },
        {
          label: 'columnTxs', prop: 'num_txs', width: '160', class: '', router: '', key: '',
        },
        {
          label: 'columnTime', prop: 'time_ago', width: '160', class: 'txt_color_777', router: '', key: '',
        }],
      columnes2: [
        {
          label: 'columnTxHash', prop: 'tx_id', width: '180', class: 'txt_color_org tdlink', router: 'transactionDetail', key: 'tx_id',
        },
        {
          label: 'columnTxType', prop: 'tx_type', width: '160', class: '', router: '', key: '',
        },
        {
          label: 'columnHeight', prop: 'block_nm', width: '160', class: 'tdlink', router: 'blocksDetail', key: 'block_nm',
        },
        {
          label: 'columnTime', prop: 'time_ago', width: '140', class: 'txt_color_777', router: '', key: '',
        }],
      blocksData: [],
      txtData: [],
      grahData: [],
      priceData: '',
      statsData: {},
      bannerData: {},
      nIntervId: null,
    };
  },
  watch: {
    '$i18n.locale': function () { // eslint-disable-line func-names
      this.getbannerData();
    },
  },
  methods: {
    getbannerData() {
      const lang = this.$i18n.locale;
      try {
        this.$axios.get(`/top/banners?lang=${lang}`)
          .then((res) => {
            this.bannerData = res.data.data;
          });
      } catch (err) {
        this.$message.error(err);
      }
    },
    getPriceData() {
      try {
        this.$axios.get('/top/price')
          .then((res) => {
            this.priceData = res.data.data.price;
          });
      } catch (err) {
        this.$message.error(err);
      }
    },
    getLatestData() {
      this.$axios.get('/top/stats')
        .then((res) => {
          this.statsData = res.data.data;
        })
        .catch((error) => {
          this.$message.error(error);
        });

      this.$axios.get('/top/blocks/?list_size=10')
        .then((res) => {
          if (res.data.ret_code === 200) { this.blocksData = res.data.data; }
        })
        .catch((error) => {
          this.$message.error(error);
        });

      this.$axios.get('/top/txs/?list_size=10')
        .then((res) => {
          if (res.data.ret_code === 200) { this.txtData = res.data.data; }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
  mounted() {
    this.getbannerData();
    this.getPriceData();
    this.getLatestData();
  },
  created() {
    this.nIntervId = setInterval(this.getLatestData, 3000);
  },
  beforeDestroy() {
    clearInterval(this.nIntervId);
  },
};
</script>
