import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/blocks',
    name: 'blocks',
    component: () => import('../views/Blocks.vue'),
  },
  {
    path: '/blocks/:me',
    name: 'blocksDetail',
    props: true,
    component: () => import('../views/BlocksDetail.vue'),
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('../views/Transactions.vue'),
  },
  {
    path: '/transactions/:me',
    name: 'transactionDetail',
    component: () => import('../views/TransactionDetail.vue'),
  },
  {
    path: '/validators',
    name: 'validators',
    component: () => import('../views/Validators.vue'),
  },
  {
    path: '/validators/:me',
    name: 'validatorDetail',
    component: () => import('../views/ValidatorDetail.vue'),
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import('../views/Accounts.vue'),
  },
  {
    path: '/accounts/:me',
    name: 'accountDetail',
    component: () => import('../views/AccountDetail.vue'),
  },
  {
    path: '*',
    name: '404',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

export default router;
