<template>
  <div class="main_header">
      <el-row  :gutter="40">
        <el-col :lg="14">
          <p class="main_header_copy_1 block mt10">
            {{ $t('mBannerHeader') }}
          </p>
          <p class="el-table-m main_header_copy_2">
            The GURU Blockchain Explorer
          </p>
          <div class="mt30 w-auto main_search">
            <SearchForm></SearchForm>
          </div>
        </el-col>
        <el-col :lg="10" >
          <div class="block mt10">
            <el-carousel trigger="click" height="182px"
                style="overflow: hidden !important; cursor:pointer;">
              <el-carousel-item v-for="(banner, index) in banners.list" :key="index">
                <div
                  class="bg-img"
                  @click="handleOpenDialog(banner)"
                  :style="{ backgroundImage: 'url(' + `${banners.domain_url}${banner.url}`+')' }"
                />
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-col>
      </el-row>

      <el-dialog
        center
        :title="dialogItem.title"
        :visible.sync="dialogVisible"
        :before-close="handleCloseDialog"
        :destroy-on-close="true"
        width= "480px"
      ><span v-html="dialogItem.post" />
      </el-dialog>
  </div>
</template>

<script>
import SearchForm from '@/components/SearchForm.vue';

export default {
  name: 'Mbanner',
  components: {
    SearchForm,
  },
  props: {
    banners: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_API_URL,
      dialogVisible: false,
      dialogItem: {},
    };
  },
  methods: {
    handleOpenDialog(el) {
      this.dialogItem = el;
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogItem = {};
      this.dialogVisible = false;
    },
  },
};
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .bg-img {
    width: 100%;
    height: 0;
    padding-top: 182px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* background-image: url('~@/assets/images/no-image.png'); */
  }
</style>
