import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ko from './ko.json';
import en from './en.json';
// import elementEN from 'element-ui/lib/locale/lang/en'
// import elementKO from 'element-ui/lib/locale/lang/ko'

// const enstr = Object.assign(en,elementEN)

Vue.use(VueI18n);
const messages = {
  ko,
  en,
};

const i18n = new VueI18n({
  locale: 'en',
  messages,
});

export default i18n;
