<template>
  <div class="m_table">
    <span class="Latest-Blocks">
      {{ title }}
    </span>
    <div class="el-table el-table--fit el-table--enable-row-hover
                el-table--enable-row-transition"
         style="width: 100%;height:550px;">
      <div class="el-table__header-wrapper">
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__header">
        <thead class="has-gutter"><tr class="">
        <th v-for="(col, index) in columnes" :key="index" class="el-table__cell">
          <div class="cell">{{$t(col.label)}}</div></th>
        </tr>
        </thead>
      </table>
      </div>

      <div class="el-table__body-wrapper is-scrolling-none">
        <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
         <tbody is="transition-group" name="list" >
            <tr v-for="(data, idx) in computedList"
                v-bind:key="data[columnes[0].prop]"
                :class="(idx%2 ==0)?'el-table__row' :'el-table__row el-table__row--striped'" >
              <template  v-for="(col, index) in columnes">
              <td :class="`el-table_1_column_${index+1} el-table__cell ${col.class}` "
                  :key="'dd'+index"
                  @click="routerGo(col.router, data[col.key] )">
                  <div class="cell" v-if="col.prop == 'tx_id'">{{data[col.prop] | readMore}}</div>
                  <div class="cell" v-else-if="col.prop == 'tx_type'">{{$t(data[col.prop])}}</div>
                  <div class="cell" v-else>{{data[col.prop]}}</div>
              </td>
              </template>
            </tr>
        </tbody>
      </table>
      </div>
    </div>
    <el-card class="main-box el-table-m">
       <transition-group>
      <dl class="display_grid" v-for="(data, idx) in computedList"
                v-bind:key="data.block_nm"
                v-bind:data-index="idx">
        <template  v-for="(col, index) in columnes">
        <dt :key="'dt'+index" >{{$t(col.label)}}</dt>
        <dd :key="'dd'+index" :class="col.class" v-if="col.prop == 'tx_id'"
            @click="routerGo(col.router, data[col.key] )">
            {{data[col.prop] | readMore}}</dd>
        <dd :key="'dd'+index" :class="col.class"
             v-else-if="col.prop == 'tx_type'">
            {{$t(data[col.prop])}}</dd>
        <dd :key="col.prop" :class="col.class"
            @click="routerGo(col.router, data[col.key] )"
            v-else>{{data[col.prop]}}</dd>
        </template>
      </dl>
      </transition-group>
    </el-card>
    <div class="main_table_btn_area">
      <button class="main_table_btn"  @click="$router.push(`${routerName}`)">
        {{buttonName}}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mtable',
  props: {
    title: String,
    columnes: Array,
    tableData: Array,
    routerName: String,
    buttonName: String,
  },
  computed: {

    computedList() {
      return this.tableData.filter((item) => item);
    },
  },
  methods: {
    routerGo(routerName, id) {
      if (routerName === '') return;
      this.$router.push({
        name: routerName, params: { me: id },
      });
    },
  },
};
</script>
<style>
.tdlink{
  cursor: pointer;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to{
  opacity: 0;
  transform: translateX(30px);
}
.list-move {
  transition: transform 1s;
}
</style>
