<template>
  <div class="">
    <el-input
      :placeholder="placeTxt"
      class="search-input"
      :class="{ active: isFocus }"
      v-model="searchInput" @keyup.native.enter="search" @blur="blur" @focus="focus">
    <el-button slot="append" icon="el-icon-search" @click="search" ></el-button>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'GSearchFORM',
  data() {
    return {
      isFocus: false,
      searchInput: '',
      placeTxt: this.$t('txtplaceholder'),
    };
  },
  methods: {
    focus() {
      this.isFocus = true;
      this.placeTxt = '';
    },
    blur() {
      this.isFocus = false;
      this.placeTxt = this.$t('txtplaceholder');
    },
    search() {
      let routeName = '';
      this.searchInput = this.searchInput.trim();
      if (this.searchInput === '') {
        this.$message.warning(this.$t('searchInputMessage'));
        return;
      }
      if (this.searchInput.startsWith('guru1')) {
        routeName = 'accountDetail';
      } else if (this.searchInput.startsWith('guruvaloper1')) {
        routeName = 'validatorDetail';
      } else if (this.searchInput.replace(/[^0-9.]/g, '').length === this.searchInput.length) routeName = 'blocksDetail';
      else routeName = 'transactionDetail';

      this.$router.push({
        name: routeName, params: { me: this.searchInput },
      });
      if (this.$route.name.startsWith(routeName)) {
        this.$router.go(this.$router.currentRoute);
      }
    },
  },
};
</script>
