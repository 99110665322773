<template>
<div id="app">
  <div class="app-wrapper">
    <el-header>
      <div class="navWrapper fl_wrap">
          <div class="logo-header fl_left">
            <router-link to="/">
              <div id="logo">
                  <p class="logo_img"><img src="./assets/images/logo.png" width="170px;"></p>
              </div>
            </router-link>
          </div>
          <div id="menu"  class="main_nav fl_right">
            <el-menu :default-active="activeIndex"
              mode="horizontal"
              :router="true"
              background-color="#090d2b"
              text-color="#fff"
              active-text-color="#e95413"
              class="vertical_align_m">
              <el-menu-item index="/home"  :route="{ name: 'home'}">
              {{$t("menu1") }}</el-menu-item>
              <el-menu-item index="/blocks" :route="{ name: 'blocks'}">
              {{$t("menu2") }}</el-menu-item>
              <el-menu-item index="/transactions" :route="{ name: 'transactions'}">
              {{$t("menu3") }}</el-menu-item>
              <el-menu-item index="/validators" :route="{ name: 'validators'}">
              {{$t("menu4") }}</el-menu-item>
            </el-menu>
            <el-dropdown>
                <span class="el-dropdown-link">
                  {{localLange}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="switchLang('en')"
                v-if="$i18n.locale === 'ko'">ENG</el-dropdown-item>
                <el-dropdown-item @click.native="switchLang('ko')"
                v-if="$i18n.locale === 'en'">한국어</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
          </div>
          <div id="toggle" @click="select()">
            <div id="top" class="span" :class="{ active: isActive }"></div>
            <div id="middle" class="span" :class="{ active: isActive }"></div>
            <div id="bottom" class="span" :class="{ active: isActive }"></div>
          </div>
          <div id="toggle_menu"  v-if="isActive">
            <el-menu :default-active="activeIndex"
              mode="horizontal"
              :router="true"
              background-color="#090d2b"
              text-color="#fff"
              active-text-color="#090d2b"
              class="vertical_align_m">
              <el-menu-item index="/home"  @click="mmenuClick('home')">
              {{$t("menu1") }}</el-menu-item>
              <el-menu-item index="/blocks"  @click="mmenuClick('blocks')">
              {{$t("menu2") }}</el-menu-item>
              <el-menu-item index="/transactions" @click="mmenuClick('transactions')">
              {{$t("menu3") }}</el-menu-item>
              <el-menu-item index="/validators" @click="mmenuClick('validators')">
              {{$t("menu4") }}</el-menu-item>
            </el-menu>
            <div class="switch_lang">
              <span @click="switchLang('en');" class="tdlink">ENG</span>
              <span @click="switchLang('ko');" class="tdlink">한국어</span>
            </div>
          </div>
          <div class="search_gnb fl_wrap" v-if="activeIndex != '/home'">
            <div class="fl_right main_search">
              <SearchForm></SearchForm>
            </div>
          </div>
      </div>

    </el-header>
    <div class="main-container">

      <!-- <el-scrollbar ref="scrollTarget"> -->
        <section class="app-container">
          <router-view/>
        </section>
        <el-footer>
          <div class="footer_area fl_wrap">
            <div class="fl_left">
              <img src="./assets/images/logo.png" width="150px;">
            </div>
           <div class="footer_text1 fl_left">
              <dl style="margin-top:15px;font-size:5px;">
                <!-- <dt>By GURUFIN</dt>
                <dd>Multifunctional support<br> & infinite scalability</dd> -->
              </dl>
            </div>
            <div class="footer_text2 fl_left">
              <dl class="fl_right">
                <dd>
                  <a href="mailto:help@gurufin.io"><img src="./assets/images/ico_mail.png"></a>
                  <a href="https://www.linkedin.com/company/digigooz/" target="_new"><img src="./assets/images/ico_linkedin.png"></a>
                  <!-- <a href="https://medium.com/@gurufin"><img src="./assets/images/ico_dot.png"></a> -->
                  <!-- <a href="#"><img src="./assets/images/ico_youtube.png"></a> -->
                  <!-- <a href="https://www.reddit.com/user/GURUFIN_Official"><img src="./assets/images/ico_Reddit.png"></a>
                  <a href="https://twitter.com/gurufinofficial"><img src="./assets/images/ico_twitter.png"></a> -->
                </dd>
              </dl>
            </div>
          </div>
        </el-footer>
      <!-- </el-scrollbar> -->
    </div>
  </div>
</div>
</template>

<script>
import SearchForm from '@/components/SearchForm.vue';

export default {
  components: {
    SearchForm,
  },
  data() {
    return {
      isActive: false,
      localLange: 'ENG',
    };
  },
  created() {
    // let locale = navigator.language || navigator.userLanguage
    // locale = locale.substring(0, 2)
    // if (locale !== 'ko') locale = 'en' // 한국어가 아닌 경우 무조건 영어로 설정
    // this.$i18n.locale = 'en';
  },
  methods: {
    mmenuClick(routerName) {
      this.$router.push({
        name: routerName,
      }).catch((error) => error);
      this.select();
    },
    switchLang(lang) {
      if (lang === 'ko') this.localLange = '한국어';
      else { this.localLange = 'ENG'; }
      this.$i18n.locale = lang;
      // this.$validator.locale = lang;
    },
    select() {
      this.isActive = !this.isActive;
    },
  },
  computed: {
    isHome() {
      if (this.activeIndex === '/home') return false;
      return true;
    },
    activeIndex() {
      const pathName = this.$route.path;
      if (pathName.indexOf('/blocks') > -1) return '/blocks';
      if (pathName.indexOf('/transactions') > -1) return '/transactions';
      if (pathName.indexOf('/validators') > -1) return '/validators';
      if (pathName.indexOf('/accounts') > -1) return '/accounts';
      return '/home';
    },
  },
};

</script>
