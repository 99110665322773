export default {
  currency(data) {
    if (!data) { return '0'; }
    const arr = String(data).split('.');
    const regexp = /\B(?=(\d{3})+(?!\d))/g;
    if (arr[1] && arr[1].length > 6) {
      arr[1] = arr[1].substring(0, 6);
    }
    if (arr[1]) {
      return arr[0].replace(regexp, ',').concat('.<span>', arr[1], '</span>');
    }
    return arr[0].replace(regexp, ',');
  },
  readMore(val) {
    const tcnt = val.length;
    return `${val.substring(0, 6)}...${val.substring(tcnt - 7, tcnt - 1)}`;
  },
  dataFormat(val, lange) {
    let timeString = val;
    if (lange === 'ko') {
      const jsDate = new Date(val);
      const year = jsDate.getFullYear();
      const month = (`0${jsDate.getMonth() + 1}`).slice(-2);
      const day = (`0${jsDate.getDate()}`).slice(-2);
      const hours = (`0${jsDate.getHours()}`).slice(-2);
      const minutes = (`0${jsDate.getMinutes()}`).slice(-2);
      const seconds = (`0${jsDate.getSeconds()}`).slice(-2);
      timeString = `${year}년 ${month}월 ${day}일 ${hours}:${minutes}:${seconds}`;
    }
    return timeString;
  },
};
